<template>
  <nav-header :title="info.post_title" />
  <div v-html="info.post_content" style="padding: 10px">

  </div>
</template>

<script>
import NavHeader from "@/components/top/NavHeader";
import http from "@/api/http";
export default {
  name: 'XieYi',
  components: {NavHeader},
  mixins: [],
  data(){
    return {
      info:{}
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(){
      http.get("/portal/index/getPageData",{
        params:{id:this.$route.params.id}
      }).then(da=>{
        if(da.code === 1){
          this.info = da.data
        }

      })
    }
  },
  watch: {},
  computed: {}
}
</script>

<style scoped>

</style>
